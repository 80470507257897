import { A, Tooltip } from '@phoenix/all'
import { SetupLayout } from '@setup/components'
import {
  LoadLocalizations,
  Localization,
  LocalizationProvider,
  useLocalization,
} from '@workfront/localize-react'
import InfoIcon from 'phoenix-icons/dist/InfoIcon.js'
import SystemExtraLargeHeroIcon from 'phoenix-icons/dist/SystemExtraLargeHeroIcon.js'
import * as React from 'react'

import MESSAGE_KEYS from '../constants/messageKeys'

export const localizationClientFactory = (): { namespace: string } => ({
  namespace: 'oauth2',
})

export interface IntegrationPlatformPageContainerProps {
  pageTitle: JSX.Element
  children: JSX.Element
}

const IntegrationPlatformPageContainer: React.FC<IntegrationPlatformPageContainerProps> = (
  props: IntegrationPlatformPageContainerProps
) => {
  const { children, pageTitle } = props

  const [infoLabel] = useLocalization(
    'integrations.platform',
    'Integrations platform'
  )

  return (
    <LocalizationProvider clientFactory={localizationClientFactory}>
      <LoadLocalizations messageKeys={MESSAGE_KEYS}>
        <SetupLayout
          headerIcon={<SystemExtraLargeHeroIcon />}
          title={
            <div>
              {pageTitle}
              <Tooltip
                content={infoLabel}
                style={{ marginLeft: '19px', verticalAlign: 'middle' }}
                light
                delay={500}
                position="right"
              >
                <A
                  href="https://one.workfront.com/s/csh?context=2475&pubname=the-new-workfront-experience"
                  target="_blank"
                >
                  <InfoIcon color={'#454B57'} />
                </A>
              </Tooltip>
            </div>
          }
          section={
            <Localization messageKey={'setup.section'} fallback={'System'} />
          }
        >
          {children}
        </SetupLayout>
      </LoadLocalizations>
    </LocalizationProvider>
  )
}

export default IntegrationPlatformPageContainer
