import { createReactLifecycles } from 'single-spa-workfront'

import { App } from './App'

const lifecycles = createReactLifecycles({
  rootComponent: App,
  rootNode: {
    className: 'flex flex-col flex-1',
  },
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount
export const IntegrationPlatform = App
