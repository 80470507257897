// @ts-expect-error the package @types/wf-mfe__api does not exist
import { wfetch } from '@wf-mfe/api'

import {
  Application,
  ApplicationPostBody,
  ApplicationPostResponse,
  ApplicationRemoveResponse,
  ApplicationResponse,
  ApplicationsResponse,
  ApplicationUpdateResponse,
} from '../models/Application'
import { responseHandler } from './ResponseHandler'

const BASE_URL = '/integrations/platform/api'
//const BASE_URL = 'http://localhost:3005/api'

const fetchClient = (url?: string, options?: RequestInit) =>
  wfetch(
    `${BASE_URL}/v1/apps${url}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    },
    responseHandler
  )

const ApiService = {
  query(qs) {
    return fetchClient(`?${new URLSearchParams(qs)}`)
  },

  get(resource: string) {
    return fetchClient(resource)
  },

  post(resource: string, params) {
    return fetchClient(resource, {
      method: 'POST',
      body: JSON.stringify(params),
    })
  },

  put(resource: string, params) {
    return fetchClient(resource, {
      method: 'PUT',
      body: JSON.stringify(params),
    })
  },

  patch(resource: string, params) {
    return fetchClient(resource, {
      method: 'PATCH',
      body: JSON.stringify(params),
    })
  },

  remove(resource: string) {
    return fetchClient(resource, {
      method: 'DELETE',
    })
  },
}

export const ApplicationService = {
  get(applicationKey: string): Promise<ApplicationResponse> {
    return ApiService.get(`/${applicationKey}`)
  },

  create(application: ApplicationPostBody): Promise<ApplicationPostResponse> {
    return ApiService.post('/install', application)
  },

  update(application: Application): Promise<ApplicationUpdateResponse> {
    return ApiService.put(`/${application.key}`, application)
  },

  list(skip: number, limit: number): Promise<ApplicationsResponse> {
    return ApiService.query({
      skip,
      limit,
    })
  },

  remove(applicationKey: string): Promise<ApplicationRemoveResponse> {
    return ApiService.post('/uninstall', { key: applicationKey })
  },
}
