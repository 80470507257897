import {
  Alert,
  Avatar,
  Button,
  Cell,
  Dialog,
  Font,
  Layout,
  primary,
  Row,
  Text,
  toast,
} from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import ApprovalsLineIcon from 'phoenix-icons/dist/ApprovalsLineIcon.js'
import DeleteIcon from 'phoenix-icons/dist/DeleteIcon.js'
import EditIcon from 'phoenix-icons/dist/EditIcon.js'
import React, { useState } from 'react'
import { css } from 'react-emotion'

import { ApplicationService } from '../../../api/api'
import { Application } from '../../../models/Application'
import { formatDate } from '../../../utils/utils'

interface ApplicationsListProps {
  applicationList: Application[]
  setApplicationList: (apps: Application[]) => void
}

const apps = [
  {
    key: 'some-app-1',
    name: 'Xray Test Management',
    logoPath:
      'https://marketplace-cdn.atlassian.com/files/11638493-3ff7-4f84-9f2d-cd10d8a599dc',
    description:
      'Cutting Edge Test Management. Built for every member of your team to plan, test, track and release great software',
    createdAt: null,
    baseUrl: null,
  },
  {
    key: 'some-app-2',
    name: 'Tempo Timesheet',
    logoPath:
      'https://marketplace-cdn.atlassian.com/files/1459ab35-7100-4d96-b44c-fe993f5fbbcc',
    description:
      'Cutting Edge Test Management. Built for every member of your team to plan, test, track and release great software',
    createdAt: null,
    baseUrl: null,
  },
  {
    key: 'some-app-3',
    name: 'Structure',
    logoPath:
      'https://marketplace-cdn.atlassian.com/files/ad7d9534-db04-4f11-adb2-14050e7328ee',
    description:
      'Cutting Edge Test Management. Built for every member of your team to plan, test, track and release great software',
    createdAt: null,
    baseUrl: null,
  },
  {
    key: 'some-app-4',
    name: 'ScriptRunner',
    logoPath:
      'https://marketplace-cdn.atlassian.com/files/010dab48-75d2-43b5-9a86-838b1c512cc4',
    description:
      'Cutting Edge Test Management. Built for every member of your team to plan, test, track and release great software',
    createdAt: null,
    baseUrl: null,
  },
]

export const ApplicationsList: React.FC<ApplicationsListProps> = ({
  applicationList = [],
  setApplicationList,
}) => {
  const [isShowConfigureDialog, setShowConfigureDialog] = useState<boolean>(
    false
  )
  const [
    applicationToConfigure,
    setApplicationToConfigure,
  ] = useState<Application | null>()

  const [isShowUninstallDialog, setShowUninstallDialog] = useState<boolean>(
    false
  )
  const [applicationKeyToUninstall, setApplicationKeyToUninstall] = useState<
    string | null
  >()

  const [emptyListBody] = useLocalization(
    'empty.list.body',
    'Type the url and click the Submit button to get started.'
  )

  const [emptyListTitle] = useLocalization(
    'empty.list.title',
    'There aren’t any apps yet'
  )

  const [uninstallApplicationText] = useLocalization(
    'application.uninstall',
    'Uninstall Application'
  )

  const [uninstallApplicationSuccessText] = useLocalization(
    'app.successfully.uninstalled',
    'Application successfully uninstalled'
  )

  const [configureApplicationText] = useLocalization(
    'application.configure',
    'Configure Application'
  )

  const [applicationLogoAlt] = useLocalization('text.applogoalt', 'logo')

  const openUninstallDialog = (applicationId) => {
    setApplicationKeyToUninstall(applicationId)
    setShowUninstallDialog(true)
  }

  const closeUninstallDialog = () => {
    setApplicationKeyToUninstall(null)
    setShowUninstallDialog(false)
  }

  const handleApplicationUninstall = async () => {
    if (!applicationKeyToUninstall) return

    try {
      const response = await ApplicationService.remove(
        applicationKeyToUninstall
      )
      closeUninstallDialog()

      if (!response.succeeded) return

      toast.success(uninstallApplicationSuccessText)
      setApplicationList(
        applicationList.filter(
          (application) => application.key !== applicationKeyToUninstall
        )
      )
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }

  const openConfigureDialog = (application) => {
    setApplicationToConfigure(application)
    setShowConfigureDialog(true)
  }

  const closeConfigureDialog = () => {
    setApplicationToConfigure(null)
    setShowConfigureDialog(false)
  }

  return (
    <div>
      <Layout fullBleed={true}>
        <div className={popularApplicationsContainer}>
          <Row>
            {apps.map((app) => (
              <Cell span={3} key={app.key}>
                <div className={rowWrapper}>
                  <div className={popularApplicationContainer}>
                    <div>
                      <img
                        className={popularApplicationLogo}
                        src={app.logoPath}
                        alt={applicationLogoAlt}
                      />
                    </div>
                    <div>
                      <Font type="h4" className={ellipse}>
                        {app.name}
                      </Font>
                    </div>
                    <div>
                      <Text>{app.description}</Text>
                    </div>
                  </div>
                  <div className={buttonGroup}>
                    <div className={flexStyles}>
                      Verified
                      <ApprovalsLineIcon />
                    </div>
                    <Button>Install</Button>
                  </div>
                </div>
              </Cell>
            ))}
          </Row>
        </div>
      </Layout>
      <Layout fullBleed={true}>
        {applicationList.length === 0 ? (
          <Alert type="info" body={emptyListBody} title={emptyListTitle} />
        ) : (
          <>
            <Row>
              <Cell span={3} className={nameCell}>
                <Font type="h4">
                  <Localization messageKey={'text.name'} fallback={'Name'} />
                </Font>
              </Cell>
              <Cell span={3}>
                <Font type="h4">
                  <Localization messageKey={'text.key'} fallback={'Key'} />
                </Font>
              </Cell>
              <Cell span={3}>
                <Font type="h4">
                  <Localization
                    messageKey={'text.created.at'}
                    fallback={'Created at'}
                  />
                </Font>
              </Cell>
              <Cell span={3}>
                <Text>{''}</Text>
              </Cell>
            </Row>
            {applicationList.map((item) => (
              <div key={item.name} className={rowWrapper}>
                <Row verticalSpacing={3}>
                  <Cell span={3} position={'middle'} className={logoWrapper}>
                    {item.logoPath ? (
                      <img
                        className={applicationLogo}
                        src={item.logoPath}
                        alt={applicationLogoAlt}
                      />
                    ) : (
                      <div className={applicationLogo}>
                        <Avatar
                          backgroundColor="#DAE8F8"
                          textColor="4B8DDC"
                          label={item.name}
                          showLabel={false}
                          showTooltip={false}
                          type="hero"
                        />
                      </div>
                    )}
                    <Font type="h4" className={ellipse}>
                      {item.name}
                    </Font>
                  </Cell>
                  <Cell span={3} position={'middle'} className={ellipse}>
                    <Text>{item.key}</Text>
                  </Cell>
                  <Cell span={3} position={'middle'} className={ellipse}>
                    <Text>{formatDate(item.createdAt)}</Text>
                  </Cell>
                  <Cell span={3} position={'middle'}>
                    <div className={actionWrapper}>
                      <Button
                        className={buttonPadding}
                        text
                        icon={DeleteIcon}
                        onClick={() => openUninstallDialog(item.key)}
                      >
                        <Localization
                          messageKey={'action.uninstall'}
                          fallback={'Uninstall'}
                        />
                      </Button>
                      <Button
                        className={buttonPadding}
                        text
                        icon={EditIcon}
                        onClick={() => openConfigureDialog(item)}
                      >
                        <Localization
                          messageKey={'action.configure'}
                          fallback={'Configure'}
                        />
                      </Button>
                    </div>
                  </Cell>
                </Row>
              </div>
            ))}
          </>
        )}
      </Layout>
      {isShowUninstallDialog && (
        <Dialog
          onClose={() => setShowUninstallDialog(false)}
          header={uninstallApplicationText}
          footer={() => (
            <>
              <Button primary action={handleApplicationUninstall}>
                <Localization
                  messageKey={'action.uninstall'}
                  fallback={'Uninstall'}
                />
              </Button>
              <Button text action={closeUninstallDialog}>
                <Localization
                  messageKey={'action.cancel'}
                  fallback={'Cancel'}
                />
              </Button>
            </>
          )}
        >
          <Localization
            messageKey={'application.uninstall.confirm'}
            fallback={'Are you sure you want to uninstall this application?'}
          />
        </Dialog>
      )}
      {isShowConfigureDialog && (
        <Dialog
          onClose={closeConfigureDialog}
          header={configureApplicationText}
          width={700}
          footer={() => (
            <>
              <Button text action={closeConfigureDialog}>
                <Localization messageKey={'action.close'} fallback={'Close'} />
              </Button>
            </>
          )}
        >
          <>
            {getIframeUrl(applicationToConfigure) && (
              <iframe
                height={500}
                width={'100%'}
                src={getIframeUrl(applicationToConfigure)}
                title={applicationToConfigure?.name}
              />
            )}
          </>
        </Dialog>
      )}
    </div>
  )
}

const getIframeUrl = (application) => {
  return application?.baseUrl + application?.pages.configure.url || null
}

const nameCell = css`
  margin-left: 18px;
`

const ellipse = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const actionWrapper = css`
  display: flex;
  visibility: hidden;
  opacity: 0;
  justify-content: end;
  padding-right: 30px;
  transition: visibility 0s, opacity 0.2s linear;
`

const rowWrapper = css`
  border: 1px solid ${primary.gray(200)};
  margin: 10px 0;
  border-radius: 3px;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 2px 4px #00000034;
  }

  &:hover .${actionWrapper} {
    visibility: visible;
    opacity: 1;
  }
`

const logoWrapper = css`
  display: flex;
  align-items: center;
`

const buttonPadding = css`
  padding-right: 25px;
`

const applicationLogo = css`
  width: 44px;
  height: 44px;
  margin: 0 18px;
`

const popularApplicationsContainer = css`
  margin-bottom: 30px;
`

const popularApplicationContainer = css`
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const flexStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const buttonGroup = css`
  ${flexStyles};
  padding: 18px;
  border-top: 1px solid ${primary.gray(200)};
`

const popularApplicationLogo = css`
  width: 60px;
  height: 60px;
`
