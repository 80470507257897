import {
  Alert,
  Button,
  Cell,
  Dialog,
  Input,
  Layout,
  Row,
  Text,
  toast,
} from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import UploadIcon from 'phoenix-icons/dist/UploadIcon.js'
import React, { useState } from 'react'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'

import { ApplicationService } from '../../../api/api'
import { ApplicationPostResponse } from '../../../models/Application'

interface RegisterApplicationProps {
  getApplications: () => void
}

export const RegisterApplication: React.FC<RegisterApplicationProps> = ({
  getApplications,
}) => {
  const [url, setUrl] = useState('')
  const [isShowUploadDialog, setShowUploadDialog] = useState(false)

  const [uploadApplicationText] = useLocalization('upload.app', 'Upload app')
  const [inputPlaceholderText] = useLocalization(
    'enter.app.descriptor.url',
    'Enter app descriptor URL'
  )

  const toggleUploadDialog = () => {
    setShowUploadDialog(!isShowUploadDialog)
  }

  const handleOnChange = (e) => {
    if (e.target?.value) {
      setUrl(e.target.value)
    }
  }

  const handleSubmit = async () => {
    try {
      const response: ApplicationPostResponse = await ApplicationService.create(
        { url }
      )
      if (response.succeeded) {
        toggleUploadDialog()
        toast.success('Application created')
        getApplications()
      }
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }

  return (
    <>
      <Layout fullBleed={true}>
        <Row>
          <Cell span={9}>
            <Text>
              <Localization
                messageKey={
                  'you.can.install.update.enable.and.disable.apps.here'
                }
                fallback={
                  'You can install, update, enable, and disable apps here.'
                }
              />
              <Link to={'#'} className={linkStyles}>
                <Localization
                  messageKey={'find.new.apps'}
                  fallback={'Find new apps.'}
                />
              </Link>
            </Text>
          </Cell>
          <Cell span={3} className={buttonCellStyles}>
            <Button onClick={toggleUploadDialog} icon={UploadIcon} text>
              Upload
            </Button>
          </Cell>
        </Row>
      </Layout>
      {isShowUploadDialog && (
        <Dialog
          onClose={toggleUploadDialog}
          header={uploadApplicationText}
          width={700}
          footer={() => (
            <>
              <Button primary action={handleSubmit}>
                <Localization
                  messageKey={'action.upload'}
                  fallback={'Upload'}
                />
              </Button>
              <Button text action={toggleUploadDialog}>
                <Localization
                  messageKey={'action.cancel'}
                  fallback={'Cancel'}
                />
              </Button>
            </>
          )}
        >
          <Alert
            type="warning"
            title={
              <Localization
                messageKey={'make.sure.you.trust.this.app'}
                fallback="Please make sure you trust this app before proceeding."
              />
            }
            body={
              <Localization
                messageKey={'upload.action.info'}
                fallback="Apps uploaded in developer mode can access data and have not been reviewed or approved by Adobe Workfront. Adobe Workfront's Privacy Policy and Terms of Use do not apply."
              />
            }
          />
          <Input
            onChange={handleOnChange}
            name={'manifest-url'}
            placeholder={inputPlaceholderText}
            label={
              <Localization
                messageKey={'enter.app.descriptor.url.to.upload'}
                fallback={
                  'Enter the app descriptor URL to upload the app and install'
                }
              />
            }
          />
        </Dialog>
      )}
    </>
  )
}

const buttonCellStyles = css`
  display: flex;
  justify-content: flex-end;
`

const linkStyles = css`
  color: blue;
  margin-left: 3px;
`
