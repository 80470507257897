import { Text, toast } from '@phoenix/all'
import { SetupLayoutContent } from '@setup/components'
import { Localization } from '@workfront/localize-react'
import React, { useCallback, useEffect, useState } from 'react'
import { css } from 'react-emotion'

import { ApplicationService } from '../../api/api'
import { Application, ApplicationsResponse } from '../../models/Application'
import IntegrationPlatformPageContainer from '../IntegrationPlatformPageContainer'
import { ApplicationsList } from './ApplicationsList/ApplicationsList'
import { RegisterApplication } from './RegisterApplication/RegisterApplication'

export const Applications: React.FC = () => {
  const [applications, setApplications] = useState<Application[]>([])

  const getApplications = useCallback(async () => {
    try {
      const response: ApplicationsResponse = await ApplicationService.list(
        0,
        10
      )
      if (response.apps) {
        setApplications(response.apps)
      }
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }, [])

  useEffect(() => {
    getApplications().then()
  }, [getApplications])

  return (
    <IntegrationPlatformPageContainer
      pageTitle={
        <Localization
          messageKey={'integrations.platform'}
          fallback={'Integrations platform'}
        />
      }
    >
      <SetupLayoutContent>
        <div className={containerStyles}>
          <div className={textContainerStyles}>
            <Text className={textStyles}>
              <Localization
                messageKey={'manage.apps'}
                fallback={'Manage apps'}
              />
            </Text>
          </div>
          <RegisterApplication getApplications={getApplications} />
          <ApplicationsList
            applicationList={applications}
            setApplicationList={setApplications}
          />
        </div>
      </SetupLayoutContent>
    </IntegrationPlatformPageContainer>
  )
}

const containerStyles = css`
  padding: 24px;
`

const textStyles = css`
  font-size: 22px;
  font-weight: bold;
`

const textContainerStyles = css`
  padding-bottom: 20px;
`
